<template>
  <v-col class="px-0 py-0">
    <v-row no-gutters align="center" style="margin-top: 40px">
      <div
        class="statusBox"
        @click="activeStatus = 'All'"
        :class="activeStatus == 'All' ? 'activeStatusBox' : ''"
      >
        Усі
      </div>
      <div
        class="statusBox"
        @click="activeStatus = 'Active'"
        :class="activeStatus == 'Active' ? 'activeStatusBox' : ''"
      >
        Активні
      </div>
      <div
        class="statusBox"
        @click="activeStatus = 'Paused'"
        :class="activeStatus == 'Paused' ? 'activeStatusBox' : ''"
      >
        Призупинені
      </div>
      <div
        class="statusBox"
        @click="activeStatus = 'Archived'"
        :class="activeStatus == 'Archived' ? 'activeStatusBox' : ''"
      >
        Завершені
      </div>
      <v-row no-gutters align="center" justify="end">
        <submit-button
          style="height: 48px !important; width: max-content; font-size: 16px"
          @click="showCreateNewPromotion = true"
          ><div
            class="adminPlusIcon"
            style="
              margin-right: 8px;
              background-color: white;
              width: 20px;
              height: 20px;
            "
          />
          Нова знижка</submit-button
        >
      </v-row>
    </v-row>
    <Loader v-if="showLoader" />
    <v-data-table
      v-else
      style="margin-top: 32px"
      :headers="headers"
      :items="promotions"
      hide-default-footer
      hide-default-header
      :items-per-page="100"
      background-color="red"
      no-data-text="Поки що ви не додали жодної знижки"
    >
      <template v-slot:header="{ props }">
        <th
          @click="
            !head.sortable && head.sortable !== false
              ? (sortBy = head.value)((sortDesc = !sortDesc))
              : ''
          "
          v-for="head in props.headers"
          :key="head.id"
          style="padding: 15px"
        >
          <v-row
            no-gutters
            align="center"
            :justify="head.align ? head.align : 'start'"
            ><output
              style="
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: 0.1em;
                color: #1b1b1b;
                cursor: pointer;
                user-select: none;
              "
              >{{ head.text }}
              <v-icon
                v-if="head.sortable !== false"
                size="25px"
                :color="head.value == sortBy ? 'black' : ''"
                >mdi-menu-swap</v-icon
              >
            </output>
          </v-row>
        </th>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-row no-gutters align="center">
          <div
            style="
              width: 8px;
              height: 8px;
              border-radius: 50%;
              margin-right: 8px;
            "
            :style="
              item?.status == 'Active' && !item.deleted_at
                ? 'background-color:#149E51'
                : item?.status == 'Paused' && !item.deleted_at
                ? 'background-color:#FFC107'
                : 'background-color:#989898'
            "
          ></div>
          <span
            class="tableItem"
            style="font-size: 16px; font-weight: 500"
            :style="
              item?.status == 'Active' && !item.deleted_at
                ? 'color: #149e51;'
                : item?.status == 'Paused' && !item.deleted_at
                ? 'color:#FFC107'
                : 'color:#989898'
            "
          >
            {{
              item?.status == "Active" && !item.deleted_at
                ? "Активна"
                : item?.status == "Paused" && !item.deleted_at
                ? "Призупинено"
                : "Завершено"
            }}</span
          >
        </v-row>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <span
          style="font-size: 16px; font-weight: 500"
          :style="item.deleted_at ? 'color:#989898' : ''"
          >{{
            item.translations.find((translate) => translate.lang == "ua")?.title
          }}</span
        >
      </template>
      <template v-slot:[`item.value`]="{ item }">
        <v-row no-gutters justify="center" style="margin: 8px 0px">
          <div
            style="
              padding: 8px;
              border-radius: 10px;
              background: rgba(20, 79, 169, 0.1);
              width: max-content;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              color: #144fa9;
            "
            :style="item.deleted_at ? 'color:#989898' : ''"
          >
            {{ item.amount }}{{ item.amount_type == "Percent" ? "%" : "грн" }}
            {{
              item.route_type == "All"
                ? "знижки на всі квитки"
                : item.route_type == "Schedule"
                ? "знижки на окремі рейси"
                : "знижки на окремі маршрути"
            }}
          </div>
        </v-row>
      </template>
      <template v-slot:[`item.period`]="{ item }">
        <v-row no-gutters align="center" justify="center">
          <div
            style="
              border-radius: 10px;
              background: rgba(20, 158, 81, 0.1);
              padding: 4px 8px;
              font-size: 14px;
              font-weight: 500;
              color: #1b1b1b;
              width: max-content;
            "
            :style="
              item?.status == 'Active' && !item.deleted_at
                ? 'background-color:rgba(20, 158, 81, 0.10);'
                : item?.status == 'Paused' && !item.deleted_at
                ? 'background-color:rgba(255, 193, 7, 0.30);'
                : 'background-color:rgba(149, 157, 165, 0.20);color:#989898;'
            "
          >
            з
            {{
              new Date(item.started_at).toLocaleDateString("uk-UA", {
                month: "2-digit",
                year: "numeric",
                day: "2-digit",
                timeZone: "Europe/Kiev",
              })
            }}
          </div>
          <div
            style="
              border-radius: 10px;
              padding: 4px 8px;
              font-size: 14px;
              font-weight: 500;
              color: #1b1b1b;
              width: max-content;
              margin-left: 4px;
            "
            :style="
              item?.status == 'Active' && !item.deleted_at
                ? 'background-color:rgba(223, 58, 58, 0.1);'
                : item?.status == 'Paused' && !item.deleted_at
                ? 'background-color:rgba(255, 193, 7, 0.30);'
                : 'background-color:rgba(149, 157, 165, 0.20);color:#989898;'
            "
          >
            з
            {{
              new Date(item.ended_at).toLocaleDateString("uk-UA", {
                month: "2-digit",
                year: "numeric",
                day: "2-digit",
                timeZone: "Europe/Kiev",
              })
            }}
          </div>
        </v-row>
      </template>
      <template v-slot:[`item.usage`]="{ item }">
        <span style="font-size: 14px; font-style: normal; font-weight: 700"
          >{{ item.usage }}/<span style="color: #989898">{{
            item.usage_limit
          }}</span></span
        >
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-row no-gutters justify="end">
          <v-menu offset->
            <template v-slot:activator="{ on, attrs }">
              <div class="iconBackground" v-bind="attrs" v-on="on">
                <div class="dotsVerticalIcon" />
              </div>
            </template>
            <div
              style="
                border-radius: 5px;
                border: 1px solid rgba(181, 181, 181, 0.3);
                background: #fcfcfc;
                width: 180px;
                padding: 20px;
                color: #515151;
                text-align: center;
                font-size: 14px;
                font-weight: 400;
              "
              v-if="!item.deleted_at"
            >
              Опції
              <div style="border-bottom: 1px solid #f2f2f0; margin-top: 5px" />
              <v-col class="px-0 py-0" style="text-align: left">
                <p
                  style="
                    margin-top: 14px;
                    margin-bottom: 16px;
                    cursor: pointer;
                    user-select: none;
                  "
                  @click="setEditPromotion(item)"
                >
                  Редагувати
                </p>
                <!-- <p
                  style="cursor: pointer; user-select: none"
                  @click="$emit('editPromotion', item.id)"
                >
                  Детальніше
                </p> -->
                <p
                  style="cursor: pointer; user-select: none"
                  :style="
                    item.status == 'Active' ? 'color: #ffc107' : 'color:#149E51'
                  "
                  @click="
                    changeDiscountStatus(
                      item.id,
                      item.status == 'Active' ? 'Paused' : 'Active'
                    )
                  "
                >
                  {{ item.status == "Active" ? "Призупинити" : "Поновити" }}
                </p>
                <p
                  style="cursor: pointer; user-select: none; color: red"
                  @click="deletePromotion(item.id)"
                >
                  Видалити
                </p>
              </v-col>
            </div>
            <div
              style="
                border-radius: 5px;
                border: 1px solid rgba(181, 181, 181, 0.3);
                background: #fcfcfc;
                width: 180px;
                padding: 20px;
                color: #515151;
                text-align: center;
                font-size: 14px;
                font-weight: 400;
              "
              v-else
            >
              Опції
              <div style="border-bottom: 1px solid #f2f2f0; margin-top: 5px" />
              <v-col class="px-0 py-0" style="text-align: left">
                <p
                  style="
                    margin-top: 14px;
                    margin-bottom: 16px;
                    cursor: pointer;
                    user-select: none;
                  "
                  @click="setEditPromotion(item)"
                >
                  Редагувати
                </p>
                <p
                  style="cursor: pointer; user-select: none"
                  :style="
                    item.status == 'Active' ? 'color: #ffc107' : 'color:#149E51'
                  "
                  @click="restoreDiscount(item.id)"
                >
                  Поновити
                </p>
              </v-col>
            </div>
          </v-menu>
        </v-row>
      </template>
    </v-data-table>
    <pagination
      :page="page"
      :quantityPage="quantityPage"
      @changePage="changePage"
    />
    <create-promotion-modal
      v-if="showCreateNewPromotion"
      :visible="showCreateNewPromotion"
      :isEdit="isEdit"
      :promotionForEdit="promotionForEdit"
      @getPromotions="getPromotions"
      @close="(showCreateNewPromotion = false), (isEdit = false)"
    />
  </v-col>
</template>

<script>
import discountService from "../../../requests/Admin/Discount/discountService";
import submitButton from "../../UI/Buttons/submitButton.vue";
import CreatePromotionModal from "./createPromotionModal.vue";
import Loader from "../../UI/Loader.vue";
import Pagination from "../../UI/pagination.vue";
export default {
  components: { submitButton, CreatePromotionModal, Loader, Pagination },
  data: () => ({
    activeStatus: "All",
    promotions: [],
    headers: [
      {
        text: "Статус",
        align: "start",
        value: "status",
        sortable: false,
      },
      { text: "Назва", value: "name", align: "center", sortable: false },
      { text: "Знижка", value: "value", align: "center", sortable: false },
      {
        text: "Період",
        value: "period",
        align: "center",
        sortable: false,
      },
      {
        text: "Використання",
        value: "usage",
        align: "center",
        sortable: false,
      },
      { text: "Опції", value: "action", sortable: false, align: "end" },
    ],
    showCreateNewPromotion: false,
    promotionForEdit: {},
    isEdit: false,
    showLoader: true,
    page: 1,
    quantityPage: 0,
  }),
  mounted() {
    this.getPromotions();
  },
  methods: {
    changePage(page) {
      this.page = page;
      this.checkStatus();
    },
    async getPromotions() {
      await discountService.getDiscounts(this.page).then((res) => {
        if (res.status == "Success") {
          this.promotions = res.data;
          this.quantityPage = res.pagination.meta.last_page;
          this.showLoader = false;
        }
      });
    },
    async getPromotionsByStatus() {
      await discountService
        .getDiscountsByStatus(this.activeStatus, this.page)
        .then((res) => {
          if (res.status == "Success") {
            this.promotions = res.data;
            this.quantityPage = res?.pagination?.meta?.last_page;
            this.showLoader = false;
          }
        });
    },

    setEditPromotion(promotion) {
      this.promotionForEdit = promotion;
      this.isEdit = true;
      this.showCreateNewPromotion = true;
    },
    async changeDiscountStatus(id, status) {
      let form = new FormData();
      form.append("status", status);
      await discountService.changeDiscountStatus(id, form).then((res) => {
        if (res.status == "Success") {
          this.checkStatus();
        }
      });
    },
    async restoreDiscount(id) {
      await discountService.restoreDiscount(id).then((res) => {
        if (res.status == "Success") {
          this.getPromotions();
        }
      });
    },
    async deletePromotion(id) {
      await discountService.deleteDiscount(id).then(() => {
        this.checkStatus();
      });
    },
    checkStatus() {
      this.showLoader = true;
      if (
        this.activeStatus !== null &&
        this.activeStatus !== undefined &&
        this.activeStatus !== "" &&
        this.activeStatus !== "All"
      ) {
        this.getPromotionsByStatus();
      } else {
        this.getPromotions();
      }
    },
  },
  watch: {
    activeStatus: {
      handler() {
        this.checkStatus();
      },
    },
  },
};
</script>

<style>
.v-text-field--outlined.v-input--dense .v-label {
  top: 18px;
}
.v-text-field--outlined.v-input--dense .v-label--active {
  top: 10px !important;
}
</style>