var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{staticClass:"px-0 py-0"},[_c('v-row',{staticStyle:{"margin-top":"40px"},attrs:{"no-gutters":"","align":"center"}},[_c('div',{staticClass:"statusBox",class:_vm.activeStatus == 'All' ? 'activeStatusBox' : '',on:{"click":function($event){_vm.activeStatus = 'All'}}},[_vm._v(" Усі ")]),_c('div',{staticClass:"statusBox",class:_vm.activeStatus == 'Active' ? 'activeStatusBox' : '',on:{"click":function($event){_vm.activeStatus = 'Active'}}},[_vm._v(" Активні ")]),_c('div',{staticClass:"statusBox",class:_vm.activeStatus == 'Paused' ? 'activeStatusBox' : '',on:{"click":function($event){_vm.activeStatus = 'Paused'}}},[_vm._v(" Призупинені ")]),_c('div',{staticClass:"statusBox",class:_vm.activeStatus == 'Archived' ? 'activeStatusBox' : '',on:{"click":function($event){_vm.activeStatus = 'Archived'}}},[_vm._v(" Завершені ")]),_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"end"}},[_c('submit-button',{staticStyle:{"height":"48px !important","width":"max-content","font-size":"16px"},on:{"click":function($event){_vm.showCreateNewPromotion = true}}},[_c('div',{staticClass:"adminPlusIcon",staticStyle:{"margin-right":"8px","background-color":"white","width":"20px","height":"20px"}}),_vm._v(" Нова знижка")])],1)],1),(_vm.showLoader)?_c('Loader'):_c('v-data-table',{staticStyle:{"margin-top":"32px"},attrs:{"headers":_vm.headers,"items":_vm.promotions,"hide-default-footer":"","hide-default-header":"","items-per-page":100,"background-color":"red","no-data-text":"Поки що ви не додали жодної знижки"},scopedSlots:_vm._u([{key:"header",fn:function({ props }){return _vm._l((props.headers),function(head){return _c('th',{key:head.id,staticStyle:{"padding":"15px"},on:{"click":function($event){!head.sortable && head.sortable !== false
            ? (_vm.sortBy = head.value)((_vm.sortDesc = !_vm.sortDesc))
            : ''}}},[_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":head.align ? head.align : 'start'}},[_c('output',{staticStyle:{"font-weight":"500","font-size":"16px","line-height":"22px","letter-spacing":"0.1em","color":"#1b1b1b","cursor":"pointer","user-select":"none"}},[_vm._v(_vm._s(head.text)+" "),(head.sortable !== false)?_c('v-icon',{attrs:{"size":"25px","color":head.value == _vm.sortBy ? 'black' : ''}},[_vm._v("mdi-menu-swap")]):_vm._e()],1)])],1)})}},{key:`item.status`,fn:function({ item }){return [_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('div',{staticStyle:{"width":"8px","height":"8px","border-radius":"50%","margin-right":"8px"},style:(item?.status == 'Active' && !item.deleted_at
              ? 'background-color:#149E51'
              : item?.status == 'Paused' && !item.deleted_at
              ? 'background-color:#FFC107'
              : 'background-color:#989898')}),_c('span',{staticClass:"tableItem",staticStyle:{"font-size":"16px","font-weight":"500"},style:(item?.status == 'Active' && !item.deleted_at
              ? 'color: #149e51;'
              : item?.status == 'Paused' && !item.deleted_at
              ? 'color:#FFC107'
              : 'color:#989898')},[_vm._v(" "+_vm._s(item?.status == "Active" && !item.deleted_at ? "Активна" : item?.status == "Paused" && !item.deleted_at ? "Призупинено" : "Завершено"))])])]}},{key:`item.name`,fn:function({ item }){return [_c('span',{staticStyle:{"font-size":"16px","font-weight":"500"},style:(item.deleted_at ? 'color:#989898' : '')},[_vm._v(_vm._s(item.translations.find((translate) => translate.lang == "ua")?.title))])]}},{key:`item.value`,fn:function({ item }){return [_c('v-row',{staticStyle:{"margin":"8px 0px"},attrs:{"no-gutters":"","justify":"center"}},[_c('div',{staticStyle:{"padding":"8px","border-radius":"10px","background":"rgba(20, 79, 169, 0.1)","width":"max-content","font-size":"16px","font-style":"normal","font-weight":"500","color":"#144fa9"},style:(item.deleted_at ? 'color:#989898' : '')},[_vm._v(" "+_vm._s(item.amount)+_vm._s(item.amount_type == "Percent" ? "%" : "грн")+" "+_vm._s(item.route_type == "All" ? "знижки на всі квитки" : item.route_type == "Schedule" ? "знижки на окремі рейси" : "знижки на окремі маршрути")+" ")])])]}},{key:`item.period`,fn:function({ item }){return [_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('div',{staticStyle:{"border-radius":"10px","background":"rgba(20, 158, 81, 0.1)","padding":"4px 8px","font-size":"14px","font-weight":"500","color":"#1b1b1b","width":"max-content"},style:(item?.status == 'Active' && !item.deleted_at
              ? 'background-color:rgba(20, 158, 81, 0.10);'
              : item?.status == 'Paused' && !item.deleted_at
              ? 'background-color:rgba(255, 193, 7, 0.30);'
              : 'background-color:rgba(149, 157, 165, 0.20);color:#989898;')},[_vm._v(" з "+_vm._s(new Date(item.started_at).toLocaleDateString("uk-UA", { month: "2-digit", year: "numeric", day: "2-digit", timeZone: "Europe/Kiev", }))+" ")]),_c('div',{staticStyle:{"border-radius":"10px","padding":"4px 8px","font-size":"14px","font-weight":"500","color":"#1b1b1b","width":"max-content","margin-left":"4px"},style:(item?.status == 'Active' && !item.deleted_at
              ? 'background-color:rgba(223, 58, 58, 0.1);'
              : item?.status == 'Paused' && !item.deleted_at
              ? 'background-color:rgba(255, 193, 7, 0.30);'
              : 'background-color:rgba(149, 157, 165, 0.20);color:#989898;')},[_vm._v(" з "+_vm._s(new Date(item.ended_at).toLocaleDateString("uk-UA", { month: "2-digit", year: "numeric", day: "2-digit", timeZone: "Europe/Kiev", }))+" ")])])]}},{key:`item.usage`,fn:function({ item }){return [_c('span',{staticStyle:{"font-size":"14px","font-style":"normal","font-weight":"700"}},[_vm._v(_vm._s(item.usage)+"/"),_c('span',{staticStyle:{"color":"#989898"}},[_vm._v(_vm._s(item.usage_limit))])])]}},{key:`item.action`,fn:function({ item }){return [_c('v-row',{attrs:{"no-gutters":"","justify":"end"}},[_c('v-menu',{attrs:{"offset-":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"iconBackground"},'div',attrs,false),on),[_c('div',{staticClass:"dotsVerticalIcon"})])]}}],null,true)},[(!item.deleted_at)?_c('div',{staticStyle:{"border-radius":"5px","border":"1px solid rgba(181, 181, 181, 0.3)","background":"#fcfcfc","width":"180px","padding":"20px","color":"#515151","text-align":"center","font-size":"14px","font-weight":"400"}},[_vm._v(" Опції "),_c('div',{staticStyle:{"border-bottom":"1px solid #f2f2f0","margin-top":"5px"}}),_c('v-col',{staticClass:"px-0 py-0",staticStyle:{"text-align":"left"}},[_c('p',{staticStyle:{"margin-top":"14px","margin-bottom":"16px","cursor":"pointer","user-select":"none"},on:{"click":function($event){return _vm.setEditPromotion(item)}}},[_vm._v(" Редагувати ")]),_c('p',{staticStyle:{"cursor":"pointer","user-select":"none"},style:(item.status == 'Active' ? 'color: #ffc107' : 'color:#149E51'),on:{"click":function($event){return _vm.changeDiscountStatus(
                    item.id,
                    item.status == 'Active' ? 'Paused' : 'Active'
                  )}}},[_vm._v(" "+_vm._s(item.status == "Active" ? "Призупинити" : "Поновити")+" ")]),_c('p',{staticStyle:{"cursor":"pointer","user-select":"none","color":"red"},on:{"click":function($event){return _vm.deletePromotion(item.id)}}},[_vm._v(" Видалити ")])])],1):_c('div',{staticStyle:{"border-radius":"5px","border":"1px solid rgba(181, 181, 181, 0.3)","background":"#fcfcfc","width":"180px","padding":"20px","color":"#515151","text-align":"center","font-size":"14px","font-weight":"400"}},[_vm._v(" Опції "),_c('div',{staticStyle:{"border-bottom":"1px solid #f2f2f0","margin-top":"5px"}}),_c('v-col',{staticClass:"px-0 py-0",staticStyle:{"text-align":"left"}},[_c('p',{staticStyle:{"margin-top":"14px","margin-bottom":"16px","cursor":"pointer","user-select":"none"},on:{"click":function($event){return _vm.setEditPromotion(item)}}},[_vm._v(" Редагувати ")]),_c('p',{staticStyle:{"cursor":"pointer","user-select":"none"},style:(item.status == 'Active' ? 'color: #ffc107' : 'color:#149E51'),on:{"click":function($event){return _vm.restoreDiscount(item.id)}}},[_vm._v(" Поновити ")])])],1)])],1)]}}],null,true)}),_c('pagination',{attrs:{"page":_vm.page,"quantityPage":_vm.quantityPage},on:{"changePage":_vm.changePage}}),(_vm.showCreateNewPromotion)?_c('create-promotion-modal',{attrs:{"visible":_vm.showCreateNewPromotion,"isEdit":_vm.isEdit,"promotionForEdit":_vm.promotionForEdit},on:{"getPromotions":_vm.getPromotions,"close":function($event){(_vm.showCreateNewPromotion = false), (_vm.isEdit = false)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }