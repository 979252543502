import requestService from "../../requestService";
import store from '@/store';

export default {
    async createDiscount(form) {
        const response = await requestService.post(`/cp_dash/discount`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async updateDiscount(id, form) {
        const response = await requestService.post(`/cp_dash/discount/${id}`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async changeDiscountStatus(id, form) {
        const response = await requestService.post(`/cp_dash/discount/status/${id}`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async getDiscounts(page) {
        const response = await requestService.get(`/cp_dash/discount?page=${page}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async getDiscountsByStatus(status,page) {
        const response = await requestService.get(`/cp_dash/discount/status/${status}?page=${page}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async getDiscount(id) {
        const response = await requestService.get(`/cp_dash/discount/${id}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async restoreDiscount(id) {
        const response = await requestService.post(`/cp_dash/discount/restore/${id}`,{}, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async deleteDiscount(id) {
        const response = await requestService.delete(`/cp_dash/discount/${id}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
}